@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f5f7fc;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.trans {
    filter: brightness(0) invert(1);
}

@keyframes ltr {
    0% {
        left: -100%;
    }
    100% {
        left: 0;
    }
}

@keyframes rtl {
    0% {
        left: 0%;
    }
    100% {
        left: -100%;
    }
}

.open-left-to-right-drawer {
    animation: ltr 0.2s ease-in-out;
}

.open-left-to-right-drawer.hidden {
    animation: ltr 1.1s ease-in-out;
}


.line {
    @apply h-[1px] my-2 bg-gray-300 rounded-full dark:bg-darkBgTertiary;
}

@keyframes roll-down {
    0% {
        max-height: 0;
    }
    100% {
        max-height: 1000px; /* Adjust this value as needed */

    }
}

.roll-down-animation {
    animation: roll-down 0.5s ease-in-out;
}

@keyframes roll-up {
    0% {
        max-height: 1000px;
    }
    100% {
        max-height: 0px; /* Adjust this value as needed */

    }
}

.roll-up-animation {
    animation: roll-up 0.5s ease-in-out;
    overflow: hidden; /* Hide any content that overflows the max-height */
}


.filter-class {
    @apply overflow-hidden lg:overflow-auto !important;
}

.page-padding {
    @apply p-8 md:p-8 lg:p-10 xl:p-12 ;
}


.card  {
    @apply bg-white dark:bg-darkBgSecondary shadow-lg rounded-lg overflow-hidden;
}


.header span {
    color: #5D6975;
    text-align: right;
    width: 52px;
    margin-right: 10px;
    display: inline-block;
    font-size: 0.9em;
}

.header div {
    white-space: nowrap;

}


.color-transition {
    @apply transition-colors duration-300;
}

